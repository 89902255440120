import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Style/Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SkeletonTypeThree from './Components/SkeletonTypeThree';
import SkeletonTypeOne from './Components/SkeletonTypeOne';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// import ProgressBall from "rc-progress-ball";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import IconSelector from 'components/IconSelector/IconSelector';

import Viewmore1 from './Viewmore/Viewmore1';
import Viewmoree1 from './Viewmore/Viewmoree1';
import axios from '../../axios/axios-default';
import LastUpdate from './LastUpdate';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function HighlightsTypeThree(props) {
  const starArray = [0, 1, 2, 3, 4];
  const {
    loading,
    title,
    total,
    colorCode,
    icon,
    tileColor,
    categoryType,
    action,
    dataRow,
    firstReportType,
    period,
  } = props;
  const classes = useStyles();
  const percentage = 90;
  const [openViewMore1, setOpenViewMore1] = useState(false);
  const [subTitl, setSubTtil] = useState('');
  const [data, setData] = useState([]);
  const [loadingTile, setLoadingTile] = useState(false);
  const [lastUpdate, setLastUpdate] = React.useState('');
  const [firstTab, setFirstTab] = useState([]);
  const [isDataNull, setIsDataNull] = useState(true);

  const getValue = () => {
    var value = 0;
    if (data.length > 0) {
      value = Number(data[0].percentage);
    }
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  };

  const getStar = () => {
    var startCount = 0;
    var total = 0;
    if (data.length > 0) {
      total = Number(data[0].percentage);
    }
    if (total == 100) {
      startCount = 5;
    } else if (total >= 80) {
      startCount = 4;
    } else if (total >= 60) {
      startCount = 3;
    } else if (total >= 40) {
      startCount = 2;
    } else if (total >= 20) {
      startCount = 1;
    } else {
      startCount = 0;
    }

    return startCount;
  };
  const onClickhandle = (subtitl) => {
    if (data.length > 0) {
      if (data[0].first_table != null) {
        setFirstTab(data[0].first_table);
        setOpenViewMore1(true);
      }
    }
  };

  const viewMoreClose = () => {
    setOpenViewMore1(false);
  };

  const handleIconImage = (iconImage) => {
    // console.log("Image Icon Name",iconImage);
  };

  useEffect(() => {
    let key = 'config_' + dataRow.id;
    let oldData = localStorage.getItem(key);

    if (oldData != null && oldData != undefined) {
      setLoadingTile(true);
      let json = JSON.parse(oldData);
      setLastUpdate(json.date);
      setData(json.data);
      setLoadingTile(false);
    } else {
      setLoadingTile(true);
      getDataForTiles(dataRow);
    }
  }, [dataRow]);

  const refresh = () => {
    setLoadingTile(true);
    getDataForTiles(dataRow);
  };

  const getDataForTiles = async (config) => {
    let apiString = 'notfound';
    let newDataArray = [];
    if (config !== null) {
      if (config.action == 'Customer Satisfaction') {
        apiString = 'getCustomerSatisfactionHighlight?highlightId=' + config.id;
      }

      if (config.action == 'Employee Satisfaction') {
        apiString = 'getEmployeeSatisfactionHighlight?highlightId=' + config.id;
      }

      // apiString = 'getJobCountHighlight?highlightId='+config.id;
      if (apiString != 'notfound') {
        await axios
          .get(apiString)
          .then((result) => {
            if (result.status == '200') {
              if (result.data !== null) {
                setIsDataNull(false);
              }

              let date = new Date();
              let dateString =
                date.toLocaleDateString() + ' | ' + date.toLocaleTimeString();
              let obj = {
                configId: config.id,
                date: dateString,
                data: result.data,
              };
              let key = 'config_' + config.id;
              localStorage.setItem(key, JSON.stringify(obj));
              setLastUpdate(dateString);
              setData(result.data);
              setLoadingTile(false);
            } else {
            }
          })
          .catch((error) => {
            setLoadingTile(false);
          });
      } else {
        setLoadingTile(false);
      }
    }
  };

  return (
    <div>
      {openViewMore1 === true ? (
        <Viewmore1
          open={openViewMore1}
          viewMoreClose={viewMoreClose}
          title={title}
          SubTitle={title}
          tileBody={title}
          categoryType={categoryType}
          action={action}
          tableData={firstTab}
          firstReportType={firstReportType}
        />
      ) : (
        ''
      )}
      {loadingTile ? (
        <SkeletonTypeOne title={title} colorCode={colorCode} />
      ) : (
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={3} style={{ marginTop: '-0.5rem' }}>
              <div
                className={classes.imgBox}
                style={{ backgroundColor: colorCode }}
              >
                <IconSelector
                  IconImage={handleIconImage}
                  hasIconImage={icon}
                  TileColor={tileColor}
                />
                {/* <InsertEmoticonIcon className={classes.icon} /> */}
              </div>
            </Grid>
            <Grid item xs={9} className={classes.topRight}>
              <Tooltip title={title} placement="top" arrow>
                <Typography noWrap className={classes.title}>
                  {title.toUpperCase()}
                </Typography>
              </Tooltip>
              <Typography noWrap className={classes.period}>
                    {period.toUpperCase()}
              </Typography>
              <div
                className={classes.topRightType2}
                onClick={() => {
                  onClickhandle();
                }}
              >
                <Typography
                  className={classes.title1}
                  style={{ color: colorCode }}
                >
                  {getValue()}%
                </Typography>
              </div>
            </Grid>
          </Grid>

          {
            <div className={classes.desView2}>
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.starContainer}>
                  {starArray.map((val) => {
                    return getStar() == 0 ? (
                      <StarIcon className={classes.noStar} />
                    ) : getStar() > val ? (
                      <StarIcon
                        className={classes.star}
                        style={{ color: tileColor }}
                      />
                    ) : (
                      <StarIcon className={classes.noStar} />
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          }

          <LastUpdate refresh={refresh} lastUpdate={lastUpdate} />
        </Paper>
      )}
    </div>
  );
}

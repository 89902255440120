/* eslint-disable no-empty */
import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { Typography } from '@material-ui/core';
import styles from './ServiceRequest.module.css';
import {
  serviceRequestProperties,
  getServiceRequestForm,
} from 'Util/Property/ServiceRequestProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import {
  getAllCustomers,
  getCustomerByName,
  getCustomerByContactNo,
  getCustomersByTid,
  searchCustomersTid,
} from 'services/Customer/CustomerService';
import { getActiveCategoryList } from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import * as serviceRequestCalls from 'services/ServiceRequest/ServiceRequestService';
import { getCheckListsByModelIdAndSRTypeId } from 'services/CheckList/ChecklistService';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import { serviceRequestCategoryProperties } from 'Util/Property/ServiceRequestCategoryProperties';
import checklistProperties from 'Util/Property/CheckListProperties';
import { getBestMatchPriorityConfiguration } from 'services/PriorityConfiguration/PriorityConfigurationService';
import { priorityConfigurationProperties } from 'Util/Property/PriorityConfigurationProperties';
import {
  getProduct,
  getProductBySerial,
  getProductsByCustomerAndModel,
  getProductByCustomer,
  getAllProductAccessoriesByProductCategory,
} from 'services/ProductAndServices/ProductService';
import { Link } from 'react-router-dom';
import { productProperties } from 'Util/Property/ProductProperties';
import { getProductServiceAgreementsOfProduct } from 'services/ProductAndServices/ProductServiceAgreement';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { handleErrorMessage } from 'services/CommonService';
import { isCustomerUser } from '../../../Util/Util';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import generalProperties from 'Util/Property/GeneralProperties';
import History from 'views/History/History';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import WorkflowRoute from 'views/WorkflowRoute/WorkflowRoute';
import ImagePreview from 'components/ImagePreview/ImagePreview';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import axios from '../../../axios/axios-default';
import Spinner from 'components/Spinner/Spinner.js';
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

  
const paperUseStyles = makeStyles((theme) => ({
root: {
  display: "flex",
  flexWrap: "wrap",
  width: "50%",
  maxHeight: "360px",
  marginBottom: "10px",
  overflowY: "auto", // Align children to the top
  "& > *": {
    margin: theme.spacing(0.25), // Reduce margin
    padding: theme.spacing(0.5), // Reduce padding
    width: "100%",
    height: "auto",
  },
},
}));

const boxUseStyles = makeStyles({
root: {
  minWidth: 275,
  padding: 0, // Remove padding
  margin: 5,  // Remove margin
},
title: {
  fontSize: 14,
  margin: 0,
},
pos: {
  marginBottom: 5,
  marginTop: 0,
},
});

const baseUrl = UrlProperties.baseUrl.split(':8089')[0];

const ServiceRequest = (props) => {
  const paperClass = paperUseStyles();
  const boxClass = boxUseStyles();
  const [serviceRequestForm, setServiceRequestForm] = useState(
    getServiceRequestForm()
  );
  const [serviceRequestHeading, setServiceRequestHeading] = useState(
    serviceRequestProperties.addServiceRequest
  );
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [serviceRequestId, setServiceRequestId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [asyncDataState, setAsyncDataState] = useState({
    customer_id: false,
    request_type: false,
    product_category_id: false,
    product_brand_id: false,
    product_model_id: false,
    serial_number: false,
    service_agreement_id: false,
    priority: false,
    assign_user_id: false,
    status: false,
    stage_id: false,
    tid_number: false,
    product_category_accessories: false,
  });
  const [prioriyConfiguration, setPrioriyConfiguration] = useState({});
  const [isUserChangedPriority, setIsUserChangedPriority] = useState(false);
  const [oldComments, setOldComments] = useState('');
  const [jobComments, setJobComments] = useState([]);
  const [products, setProdcuts] = useState({});
  const [serviceRequestData, setServiceRequestData] = useState({
    serviceRequest: null,
    isUserPriority: null,
  });
  const [permissions, setPermissions] = useState({});
  const [viewMore, setViewMore] = useState(false);

  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });

  const [isWfRouteDialogOpen, setIsWfRouteDialogOpen] = useState(false);

  const [initialState, setInitialState] = useState({
    name: '',
    customer_id: '',
    request_type: '',
    product_category_id: '',
    product_brand_id: '',
    product_model_id: '',
    serial_number: '',
    estimation_id: '',
    service_agreement_id: '',
    estimated_time_hours: '0',
    estimated_time_minutes: '0',
    check_list_id: '',
    assign_user_id: '',
    priority: 'MEDIUM',
    trNumber: '',
    invoice_no: '',
    invoice_date: null,
    payment_reference: '',
    payment_date: null,
    target_delivery_date: null,
    delivered_date: null,
    status: '',
    stage_id: '',
    newComment: '',
    accessoryIds: [],
  });
  const [routeOptions, setRouteOptions] = useState([]);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openEstimationDialog, setOpenEstimationDialog] = useState(false);
  const [selectedModel, setSelectedModel] = useState({
    brandID: '',
    catID: '',
    modelID: '',
  });
  const [snNumber, setSnNumber] = useState(null);
  const [isfirstClicked, setIsFirstClicked] = useState(true);
  const [descriptionVal, setDescriptionVal] = useState('');
  const [descriptionValDef, setDescriptionValDef] = useState('');
  const [descSuggestions, setDescSuggestions] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [requestTypes, setRequestTypes] = useState([]);
  const [requestTypesName, setRequestTypesName] = useState('');
  const [estimations, setEstimations] = useState([]);
  // const [productId, setProductId] = useState('');
  const [saIssueDate, setSaIssueDate] = useState('');
  const [subStatusId, setSubStatusId] = useState('');
  const [productDtls, setProductDtls] = useState({
    soldPrice: '',
    saleInvoiceNumber: '',
    mid: '',
  });

  const [productsByCustomer, setProductsByCustomer] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isNewSr, setIsNewSr] = useState(true);

  const DEFAULT_SPAMED_CUSTOMER = "Cba"
  const DEFAULT_SPAMED_CUSTOMER_ID = "1"
  
  useEffect(() => {
    setIsLoading(true);
    serviceRequestForm['planned_completion_date'].isHide = !viewMore;
    serviceRequestForm['actual_completion_date'].isHide = !viewMore;
    serviceRequestForm['trNumber'].isHide = !viewMore;
    serviceRequestForm['invoice_no'].isHide = !viewMore;
    serviceRequestForm['invoice_date'].isHide = !viewMore;
    serviceRequestForm['payment_reference'].isHide = !viewMore;
    serviceRequestForm['payment_date'].isHide = !viewMore;
    serviceRequestForm['target_delivery_date'].isHide = !viewMore;
    serviceRequestForm['delivered_date'].isHide = !viewMore;
    serviceRequestForm['contact_person'].isHide = !viewMore;
    serviceRequestForm['contact_no'].isHide = !viewMore;
    serviceRequestForm['email_address'].isHide = !viewMore;
    // serviceRequestForm['service_agreement_id'].isHide = !viewMore;

    setIsLoading(false);
  }, [viewMore]);

  useEffect(() => {
    let serviceRequestId = 0;
    let customerId = 0;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    customerId = queryParam['customerId'];

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        serviceRequestForm['newComment'].isHide = true;
        setIsEdit(true);
      } else {
        // serviceRequestForm['newComment'].isHide = false;
        setIsView(true);
      }
      serviceRequestId = props.id;
      setServiceRequestHeading(serviceRequestProperties.viewServiceRequest);
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        serviceRequestId = queryParam['id'];
        setServiceRequestHeading(serviceRequestProperties.viewServiceRequest);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          serviceRequestForm['newComment'].isHide = true;
          setServiceRequestHeading(serviceRequestProperties.editServiceRequest);
        } else {
          // serviceRequestForm['newComment'].isHide = false;
          setIsView(true);
        }
      }
    }
    if (customerId > 0) {
      setCustomerInitValue(customerId);
    }
    //TODO
    // serviceRequestId = 3;
    //  setServiceRequestId(serviceRequestId);
    if (serviceRequestId > 0) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      setServiceRequestId(serviceRequestId);
      getServiceRequestData(serviceRequestId);
      serviceRequestForm['planned_completion_date'].isHide = false;
      // serviceRequestForm['actual_completion_date'].isHide = false;
      serviceRequestForm['stage_name'].isHide = false;
    } else {
      // this is loading for add new Service Request

      getBackGroundData();
      if (typeof queryParam['productId'] != 'undefined') {
        // setProductId(queryParam['productId']);
        serviceRequestForm['product_id'].inputProps.value =
          queryParam['productId'];
        serviceRequestForm['customer_id'].readOnly = true;
        serviceRequestForm['product_category_id'].readOnly = true;
        serviceRequestForm['product_brand_id'].readOnly = true;
        serviceRequestForm['product_model_id'].readOnly = true;
        serviceRequestForm['serial_number'].readOnly = true;
        serviceRequestForm['tid_number'].readOnly = true;
        getDataForProduct(queryParam['productId']);
      } else if (!serviceRequestId && customerId > 0) {
        customerChangeHandler(customerId);
      }
      serviceRequestForm['stage_comment_List'].isHide = true;
      serviceRequestForm['assign_user_id'].isHide = true;
      serviceRequestForm['comments'].isHide = true;
      serviceRequestForm['stage_name'].isHide = true;
    }

    let permissionArray = [
      PermissionProperties.viewHistory,
      PermissionProperties.editServiceRequest,
      PermissionProperties.addServiceRequest,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  useEffect(() => {
    if (serviceRequestData.serviceRequest !== null) {
      getBackGroundData(serviceRequestData.isUserPriority);
      // customerChangeHandler(
      //   serviceRequestData.serviceRequest.customer_id,
      //   serviceRequestData.serviceRequest
      // );
      setCustomerData(
        serviceRequestData.serviceRequest.customer_id,
        serviceRequestData.serviceRequest.customerName,
        serviceRequestData.serviceRequest.customerContactNo
      );
      setIsLoading(false);
    }
  }, [serviceRequestData]);

  const setCustomerData = (customerId,customerName,customerContactNo) => {
    let customerOptions = [];
    customerOptions.push({
      value: parseInt(customerId),
      displayValue: customerName,
    });

    let phoneOptions = [];
    phoneOptions.push({
      value: customerContactNo,
      displayValue: customerContactNo,
    });
    setDataToServiceRequestForm(
      'customer_id',
      customerOptions,
      true
    );
    setDataToServiceRequestForm(
      'phone',
      phoneOptions,
      true
    );
  };

  const setCustomerInitValue = (customerId) => {
    serviceRequestForm['customer_id'].inputProps.initvalue = parseInt(
      customerId
    );
    serviceRequestForm['customer_id'].readOnly = true;
  };

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }
    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...serviceRequestForm };
      setServiceRequestForm(formData);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const getRouteOptions = async (serviceRequestId, stageName) => {
    serviceRequestCalls
      .getRouteOptions(serviceRequestId)
      .then((result) => {
        if (result.status === 200) {
          const routeOptions = [...result.data];
          if (stageName === 'Need Further Attention') {
            const reqBody = {
              type: 'SERVICE_REQUEST',
              name: 'Assigned',
              srId: serviceRequestId,
            };
            serviceRequestCalls
              .getRouteOptionsByWorkflowStageTypeAndName(reqBody)
              .then((routeOpResult) => {
                if (routeOpResult.status === 200) {
                  if (routeOpResult.data != null) {
                    routeOptions.push(routeOpResult.data);
                  }
                }
                setRouteOptions(routeOptions);
              });
          } else {
            setRouteOptions(routeOptions);
          }

          setIsLoading(false);
        } else {
          handleErrorMessage(
            result.data,
            generalProperties.messages.error.routeData
          );
        }
        // setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, generalProperties.messages.error?.routeData);
      });
  };

  /**
   * Load service request when view and edit
   * @param {*} serviceRequestId
   */
  const getServiceRequestData = async (serviceRequestId) => {
    setIsLoading(true);
    let isUserPriority = isUserChangedPriority;

    // const _srData = props.location.selectedSr;
    await serviceRequestCalls
      .getServiceRequest(serviceRequestId)
      .then((result) => {
        _applySrData(result.data, isUserPriority);
        getRouteOptions(serviceRequestId, result.data.stage_name);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    // if (_srData === undefined) {
    //   await serviceRequestCalls
    //     .getServiceRequest(serviceRequestId)
    //     .then((result) => {
    //       _applySrData(result.data, isUserPriority);
    //     })
    //     .catch((error) => {
    //       notyDefault({
    //         type: notyTypes.ERROR,
    //         text: serviceRequestProperties.messages.error.loadData,
    //       });
    //       setIsLoading(false);
    //     });
    // } else {
    //   _applySrData(_srData, isUserPriority);
    // }
  };

  const _applySrData = (_srData, isUserPriority) => {
    let initStat = { ...initialState };
    setServiceRequestHeading(_srData.name);
    setDescriptionVal(_srData['description']);
    setDescriptionValDef(_srData['description']);
    setRequestTypesName(_srData.requestTypeName);
    setEstimations(_srData.estimation_ids);
    setSelectedModel({
      brandID: _srData['product_brand_id'],
      catID: _srData['product_category_id'],
      modelID: _srData['product_model_id'],
    });
    setJobComments(_srData.job_comments);

    for (let key in serviceRequestForm) {
      let value = '';
      if (key === 'estimated_time_hours') {
        value = parseInt(_srData['estimated_time'] / 60);
      } else if (key === 'estimated_time_minutes') {
        value = parseInt(_srData['estimated_time'] % 60);
      } else {
        value = _srData[key];
      }

      // if (key === 'serial_number') {
      //   setSnNumber(_srData['serial_number']);
      // }

      if (key === 'priority') {
        if (_srData['userPriorityValue'] === 'IGNORE') {
          value = _srData['systemPriorityValue'];
        } else {
          value = _srData['userPriorityValue'];
          isUserPriority = true;
          setIsUserChangedPriority(isUserPriority);
        }
      }
      if (key === 'stage_comment_List') {
        // if (typeof value !== 'undefined' && value !== '' && value !== null) {
        //   setOldComments(value);
        //   let comments = value.split(serviceRequestProperties.COMMENT_END);
        //   value = '';
        //   for (let key in comments) {
        //     value += comments[key] + '\n';
        //   }
        // }

        let wfStageCommentStr = '';
        const commentList = _srData['stage_comment_List'];
        if (commentList != null && commentList.length > 0) {
          commentList.map((comment, index) => {
            if (commentList.length - 1 !== index) {
              wfStageCommentStr = wfStageCommentStr + comment + `.\n\n`;
            } else {
              wfStageCommentStr = wfStageCommentStr + comment + `.`;
            }
          });
        }

        setOldComments(wfStageCommentStr);
        value = wfStageCommentStr;
      }

      if (value === null || typeof value === 'undefined') {
        if (
          key === 'invoice_date' ||
          key === 'payment_date' ||
          key === 'target_delivery_date' ||
          key === 'delivered_date' ||
          key === 'actual_completion_date' ||
          key === 'planned_completion_date'
        ) {
          value = null;
        } else {
          value = '';
        }
      }
      if (!serviceRequestForm[key].isAsyncDropDown) {
        serviceRequestForm[key].inputProps.value = value;
        initStat[key] = value;
      } else {
        serviceRequestForm[key].inputProps.initvalue = value;
        initStat[key] = value;
      }
      if (key === 'assign_user_id') {
        let userOpt = [];
        userOpt.push({
          value: value,
          displayValue: _srData.assignUserName,
        });
        serviceRequestForm[key].inputProps.options = userOpt;
      }
      if (key === 'accessoryIds') {
        if (_srData['accessories'] != null) {
          const accessoriesOptions = [];
          const accessoryIds = [];
          _srData['accessories'].map((accessory) => {
            accessoriesOptions.push({
              value: accessory.id,
              displayValue: accessory.name,
            });
            accessoryIds.push(accessory.id);
          });

          serviceRequestForm[key].inputProps.options = accessoriesOptions;
          serviceRequestForm[key].inputProps.initvalue = accessoryIds;
        }
      }
    }
    //form initail state
    setInitialState(initStat);
    let srData = { ..._srData };
    srData.serviceRequest = _srData;
    srData.isUserPriority = isUserPriority;
    setServiceRequestData(srData);
  };

  /**
   * Get all other data in background
   * @param {*} isUserPriority
   */
  const getBackGroundData = (isUserPriority) => {
    // setIsLoading(true);
    // getAllCustomers()
    //   .then((result) => {
    //     asyncDataState.customer_id = true;
    //     if (result.status === 200) {
    //       let customerOptions = [];
    //       result.data.map((customer) =>
    //         customerOptions.push({
    //           value: customer.id,
    //           displayValue: customer.name,
    //         })
    //       );

    //       let defaultVal = setDataToServiceRequestForm(
    //         'customer_id',
    //         customerOptions,
    //         true,
    //         null
    //       );
    //       // checkForPriorityConfiguration(true, 'customer_id', defaultVal);
    //       // checkForProductUpdate(defaultVal, 'customer_id', true);
    //     } else {
    //       notyDefault({
    //         type: notyTypes.ERROR,
    //         text: result.data
    //           ? result.data.message
    //           : serviceRequestProperties.messages.error.loadCustomers,
    //       });
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     notyDefault({
    //       type: notyTypes.ERROR,
    //       text: serviceRequestProperties.messages.error.loadCustomers,
    //     });
    //   });

    getRequestTypeCategories(isUserPriority);
  };

  const getDataForProduct = (productId) => {
    setIsLoading(true);
    getProduct(productId)
      .then((result) => {
        asyncDataState.product_category_id = true;
        if (result.status === 200) {
          getProductsDataByCustomer([result.data]);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadProductCategories,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadProductCategories,
        });
      });
  };

  const getCustomersByTidHandler = async (tid) => {
    setIsLoading(true);
    getCustomersByTid(tid)
      .then(async (result) => {
        asyncDataState.customer_id = true;
        if (result.status === 200) {
          if (result.data.length > 0) {
            let customerOptions = [];
            result.data.map((customer) =>
              customerOptions.push({
                value: customer.id,
                displayValue: customer.name + ' ' + `(${customer.address})`,
              })
            );

            let defaultVal = setDataToServiceRequestForm(
              'customer_id',
              customerOptions,
              true
              // null
            );
            await customerChangeHandler(defaultVal);
          }
          setIsLoading(false);
        } else {
          clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadCustomers,
          });
        }
      })
      .catch((error) => {
        clearForm();
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadCustomers,
        });
      });
  };

  const getProductCategoryAccessories = (categoryId) => {
    setIsLoading(true);
    getAllProductAccessoriesByProductCategory(categoryId)
      .then((result) => {
        asyncDataState.product_category_accessories = true;
        if (result.status === 200) {
          const accessoriesOptions = [];
          if (result.data != null && result.data.length > 0) {
            result.data.map((accessory) => {
              accessoriesOptions.push({
                value: accessory.id,
                displayValue: accessory.name,
              });
            });
          }
          setDataToServiceRequestForm('accessoryIds', accessoriesOptions, true);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadAccessories,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadAccessories,
        });
      });
  };

  const getRequestTypeCategories = (isUserPriority) => {
    setIsLoading(true);
    getServiceRequestCategoryCall()
      .then((response) => {
        asyncDataState.request_type = true;
        if (!response.success) {
          handleErrorMessage(
            response.data,
            serviceRequestCategoryProperties.message.error.loadingError
          );
        } else {
          let requestTypeOptions = [];
          response.data.map((requestCategory) =>
            requestTypeOptions.push({
              value: requestCategory.id,
              displayValue: requestCategory.name,
            })
          );
          requestTypeOptions.sort((a, b) =>
            a.displayValue.localeCompare(b.displayValue)
          );
          setRequestTypes(requestTypeOptions);
          if (isCustomerUser() && !isView) {
            requestTypeOptions = requestTypeOptions.filter(({ displayValue }) =>
              displayValue.toLowerCase().includes('breakdown')
            );
          }
          let defaultValue = setDataToServiceRequestForm(
            'request_type',
            requestTypeOptions,
            true
          );
          checkForCheckLists('request_type', defaultValue, true);
          if (!isUserPriority) {
            // checkForPriorityConfiguration(true, 'request_type', defaultValue);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          serviceRequestCategoryProperties.message.error.loadingError
        );
      });
  };

  const dateObjectToString = (date) => {
    const dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    const newdate = year + '/' + month + '/' + day;
    return newdate;
  };

  const setProductServiceAgreement = (data, isFirstTime, srData) => {
    let currentValue =
      serviceRequestForm.service_agreement_id.inputProps.initvalue;
    let isCurrentValuePresent = false;
    let serviceAgreementOptions = data.reduce(function(filtered, option) {
      if (
        option.terminationDate >= new Date().getTime() &&
        option.active === true
      ) {
        if (option.serviceAgreementId === currentValue) {
          isCurrentValuePresent = true;
        }
        setSaIssueDate(option.issueDate);
        var someNewValue = {
          value: option.serviceAgreementId,
          displayValue:
            option.serviceAgreementName +
            ' (' +
            dateObjectToString(option.issueDate) +
            ' - ' +
            dateObjectToString(option.terminationDate) +
            ')',
        };
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
    if (
      getIsCurrentValueNeedToBeAdded(
        isCurrentValuePresent,
        currentValue,
        isFirstTime
      )
    ) {
      serviceAgreementOptions.push({
        value: serviceRequestData.serviceRequest.service_agreement_id,
        displayValue: serviceRequestData.serviceRequest.serviceAgreementName,
      });
    }
    setDataToServiceRequestForm(
      'service_agreement_id',
      serviceAgreementOptions,
      false,
      srData && srData.service_agreement_id
      // serviceRequestForm['service_agreement_id'].inputProps.initvalue
    );
  };

  const getAllServiceAgreementsForServiceRequest = (productId, isFirstTime) => {
    setDataToServiceRequestForm('service_agreement_id', [], false);
    setIsLoading(true);
    getProductServiceAgreementsOfProduct(productId)
      .then((result) => {
        asyncDataState.service_agreement_id = true;
        if (result.status === 200) {
          let currentValue =
            serviceRequestForm.service_agreement_id.inputProps.initvalue;
          let isCurrentValuePresent = false;

          let serviceAgreementOptions = result.data.reduce(function(
            filtered,
            option
          ) {
            if (
              option.terminationDate >= new Date().getTime() &&
              option.active
            ) {
              if (option.serviceAgreementId === currentValue) {
                isCurrentValuePresent = true;
              }
              setSaIssueDate(option.issueDate);
              var someNewValue = {
                value: option.serviceAgreementId,
                displayValue: option.serviceAgreementName,
              };
              filtered.push(someNewValue);
            }
            return filtered;
          },
          []);
          if (
            getIsCurrentValueNeedToBeAdded(
              isCurrentValuePresent,
              currentValue,
              isFirstTime
            )
          ) {
            serviceAgreementOptions.push({
              value: serviceRequestData.serviceRequest.service_agreement_id,
              displayValue:
                serviceRequestData.serviceRequest.serviceAgreementName,
            });
          }

          setDataToServiceRequestForm(
            'service_agreement_id',
            serviceAgreementOptions,
            false,
            serviceRequestForm['service_agreement_id'].inputProps.initvalue
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadServiceAgreements,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 404) {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              serviceRequestProperties.messages.error.notFoundServiceAgreements,
          });
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadServiceAgreements,
          });
        }
      });
  };

  function getIsCurrentValueNeedToBeAdded(
    isCurrentValuePresent,
    currentValue,
    isFirstTime
  ) {
    if (
      serviceRequestData.serviceRequest !== null &&
      isFirstTime &&
      !isCurrentValuePresent &&
      currentValue !== ''
    ) {
      return true;
    }
    return false;
  }

  const isEmpty = (element) => {
    return element === '' || typeof element === 'undefined';
  };

  const brandChangeHandler = (value, prodArr, srData) => {
    let productArr = prodArr ? prodArr : productsByCustomer;

    let modelOptions = [];
    productArr.map((prod) => {
      if (value === prod.productBrandId) {
        const modelExists = modelOptions.find(
          (el) => el.value === prod.productModelId
        );
        if (!modelExists) {
          modelOptions.push({
            value: prod.productModelId,
            displayValue: prod.productModelName,
          });
        }
      }
    });

    let modelId = setDataToServiceRequestForm(
      'product_model_id',
      modelOptions,
      false,
      srData && srData.product_model_id
    );

    return modelId;
  };
  const categoryChangeHandler = (value, prodArr, srData) => {
    let productArr = prodArr ? prodArr : productsByCustomer;
    let brandOptions = [];
    productArr.map((prod) => {
      if (value === prod.productCategoryId) {
        const brandExists = brandOptions.find(
          (el) => el.value === prod.productBrandId
        );

        if (!brandExists) {
          brandOptions.push({
            value: prod.productBrandId,
            displayValue: prod.productBrandName,
          });
        }

        getProductCategoryAccessories(value);
      }
    });

    let brandId = setDataToServiceRequestForm(
      'product_brand_id',
      brandOptions,
      false,
      srData && srData.product_brand_id
    );

    return brandId;
  };

  const modelChangeHandler = (prodArr, srData) => {
    let productArr = prodArr ? prodArr : productsByCustomer;
    let filteredProducts = [...productArr];
    let categoryId = serviceRequestForm['product_category_id'].inputProps.value;
    let brandId = serviceRequestForm['product_brand_id'].inputProps.value;
    let modelId = serviceRequestForm['product_model_id'].inputProps.value;

    if (categoryId) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productCategoryId === categoryId
      );
    }
    if (brandId) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productBrandId === brandId
      );
    }
    if (modelId) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productModelId === modelId
      );
    }

    let serialOptions = [];

    filteredProducts.map((product) => {
      serialOptions.push({
        value: product.serial,
        displayValue: product.serial,
      });
    });

    const defaultval = setDataToServiceRequestForm(
      'serial_number',
      serialOptions,
      false
      // srData && srData.serial_number
    );
    setSnNumber(defaultval);

    const selected = filteredProducts.find(
      (product) =>
        product.serial === serviceRequestForm['serial_number'].inputProps.value
    );

    if (selected) {
      serialChangeHandler(selected);
      setProductServiceAgreement(
        selected.productServiceAgreements,
        false,
        srData
      );
      // setProductId(selected.id);
      serviceRequestForm['product_id'].inputProps.value = selected.id;
      setSelectedProduct(selected);
      // getProductRelatedData(selected.id);
    }
  };

  const serialChangeHandler = (selected) => {
    if (selected != null) {
      serviceRequestForm['product_id'].inputProps.value = selected.id;
      if (!selected.active) {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.inactiveProduct,
        });
      }
      // if (selected.tid != null) {
      //   const tidOptions = [];
      //   tidOptions.push({
      //     value: selected.tid,
      //     displayValue: selected.tid,
      //   });
      // setDataToServiceRequestForm(
      //   'tid_number',
      //   tidOptions,
      //   false,
      //   selected.tid && selected.tid
      // );
      // } else {
      //   setDataToServiceRequestForm('tid_number', [], false);
      // }
    }
  };

  const clearForm = (isCusOptClear) => {
    setDataToServiceRequestForm('product_category_id', [], false);
    setDataToServiceRequestForm('product_brand_id', [], false);
    setDataToServiceRequestForm('product_model_id', [], false);
    setDataToServiceRequestForm('serial_number', [], false);
    setDataToServiceRequestForm('service_agreement_id', [], false);
    setDataToServiceRequestForm('phone', [], false);
    if (!isCusOptClear) {
      setDataToServiceRequestForm('customer_id', [], false);
      setDataToServiceRequestForm('tid_number', [], false);
    }
  };

  const customerChangeHandler = async (value, srData) => {
    setIsLoading(true);
    try {
      const result = await getProductByCustomer(value);
      if (result.data.length > 0) {
        getProductsDataByCustomer(result.data, srData);
      } else {
        notyDefault({
          type: notyTypes.ERROR,
          text: 'Selected customer has no products!',
        });
        clearForm(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getProductsDataByCustomer = (productData, srData) => {
    const prodArr = [];
    productData.map((data) => {
      prodArr.push(data);
    });
    setProductsByCustomer(prodArr);
    setSelectedProduct(prodArr[0]);

    let customerOptions = [];
    let customerTidOptions = [];
    let customerPhoneOptions = [];
    if (serviceRequestForm['customer_id'].inputProps.options.length > 0) {
      customerOptions = [
        ...serviceRequestForm['customer_id'].inputProps.options,
      ];
    } else {
      customerOptions.push({
        value: prodArr[0].customerId,
        displayValue: prodArr[0].customerName,
      });
    }

    customerTidOptions.push({
      value: prodArr[0].customerTid,
      displayValue: prodArr[0].customerTid,
    });

    customerPhoneOptions.push({
      value: prodArr[0].customerPhoneNo,
      displayValue: prodArr[0].customerPhoneNo,
    });

    setDataToServiceRequestForm(
      'customer_id',
      customerOptions,
      false,
      srData ? srData.customer_id : prodArr[0].customerId
    );

    setDataToServiceRequestForm('tid_number', customerTidOptions, false);

    setDataToServiceRequestForm('phone', customerPhoneOptions, false);

    let cateOptions = [];
    prodArr.map((prod) => {
      const cateExists = cateOptions.find(
        (el) => el.value === prod.productCategoryId
      );
      if (!cateExists) {
        cateOptions.push({
          value: prod.productCategoryId,
          displayValue: prod.productCategoryName,
        });
      }
    });

    setDataToServiceRequestForm(
      'product_category_id',
      cateOptions,
      false,
      srData && srData.product_category_id
    );
    categoryChangeHandler(
      srData ? srData.product_category_id : prodArr[0].productCategoryId,
      prodArr,
      srData
    );
    brandChangeHandler(
      srData ? srData.product_brand_id : prodArr[0].productBrandId,
      prodArr,
      srData
    );
    modelChangeHandler(prodArr, srData);
  };

  const onChangeHandeler = async (event, elId, meta) => {
    let value;
    let formData = { ...serviceRequestForm };
    let elementData = formData[elId]; // get data for form key
    if (elementData.elType == 'date') {
      value = event.toDate();
    } else {
      value = event.target.value;
    }

    // validate hours
    if (elId == 'estimated_time_hours') {
      if (!value.match('^([0-9]|[1-9][0-9]|100)$') && value !== '') {
        return;
      }
    }
    // validate minutes
    if (elId == 'estimated_time_minutes') {
      if (!value.match('^([0-5]|[1-9][0-9]|59)$') && value !== '') {
        return;
      }
    }

    if (elId !== 'description') {
      if (descriptionVal === null || descriptionVal === '') {
        setIsFirstClicked(true);
        setDescriptionVal(descriptionValDef);
      }
    }

    //validate request type Breakdown for SN number and SA reqired
    if (elId === 'request_type') {
      if (value !== '') {
        getRequestTypeById(value);
      }
    }

    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;

    setServiceRequestForm(formData);
    let userPriority = isUserChangedPriority;

    if (elId === 'product_category_id') {
      // getActiveBarandListForServcieRequest(value, userPriority);
      let brandId = categoryChangeHandler(value);
      brandChangeHandler(brandId);
      modelChangeHandler();
    }
    if (elId === 'product_brand_id') {
      // getActiveProductModelListForServiceRequest(value, userPriority);
      brandChangeHandler(value);
      modelChangeHandler();
    }
    if (elId === 'product_model_id') {
      // checkForProductUpdate(value, elId);
      modelChangeHandler();
    }

    if (elId === 'customer_id') {
      // checkForProductUpdate(value, elId);
      await customerChangeHandler(value);
      console.log('-----------------> customer ', value);
    }
    if (elId === 'phone') {
      await customerChangeHandler(value.cusId);
    }
    if (elId === 'priority') {
      userPriority = true;
    }

    if (elId === 'serial_number') {
      if (typeof value === 'object' && value.isSearch) {
        getDataForProduct(value.id);
        value = value.value;
      } else {
        const selected = productsByCustomer.find(
          (product) => product.serial === inputProps.value
        );
        serialChangeHandler(selected);
      }
    }

    if (elId === 'tid_number') {
      getCustomersByTidHandler(value);
    }

    checkForCheckLists(elId, value, false);
    setIsUserChangedPriority(userPriority);
    if (
      !userPriority &&
      (elId === 'customer_id' ||
        elId === 'request_type' ||
        elId === 'product_model_id')
    ) {
      // checkForPriorityConfiguration(false, elId, value);
    }
  };
  const searchProductBySerial = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      const elemId = 'serial_number';
      setIsLoading(true);
      clearForm();
      getProductBySerial(value)
        .then((result) => {
          asyncDataState.product_model_id = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let productMap = {};
              let serialNumberOptions = [];
              result.data.map((product) => {
                if (product.active) {
                  serialNumberOptions.push({
                    value: {
                      value: product.serial,
                      id: product.id,
                      isSearch: true,
                    },
                    displayValue: product.serial,
                  });
                }
                productMap[product.serial] = product;
              });
              let formData = serviceRequestForm[elemId];
              let inputProps = { ...formData.inputProps };
              inputProps.options = serialNumberOptions;
              formData.inputProps = inputProps;
              serviceRequestForm[elemId] = formData;
              if (serialNumberOptions.length > 0) {
                setProdcuts(productMap);
              }
            }
            setIsLoading(false);
          } else {
            clearForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadProducts,
            });
          }
        })
        .catch((error) => {
          clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadProducts,
          });
        });
      element.config.inputProps.tempValue = '';
    }
  };

  const searchCustomersTidHandler = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      const elemId = 'tid_number';
      setIsLoading(true);
      clearForm();
      searchCustomersTid(value)
        .then((result) => {
          asyncDataState.tid_number = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let tidOptions = [];
              result.data.map((tid) => {
                tidOptions.push({
                  value: tid,
                  displayValue: tid,
                });
              });
              setDataToServiceRequestForm(
                'tid_number',
                tidOptions,
                false,
                null
              );
            }
            setIsLoading(false);
          } else {
            clearForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadTids,
            });
          }
        })
        .catch((error) => {
          clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadTids,
          });
        });
    }
  };

  const checkForCheckLists = (elId, value, isFirstTime) => {
    if (elId === 'product_model_id') {
      let requestType = serviceRequestForm['request_type'].inputProps.value;
      if (requestType !== '') {
        // getChecklists(value, requestType, isFirstTime);
      }
    }

    if (elId === 'request_type') {
      // send status request here
      // getStatusList(value, isFirstTime);
      // getStageList(value, isFirstTime);
      let modelId = serviceRequestForm['product_model_id'].inputProps.value;
      if (modelId !== '') {
        // getChecklists(modelId, value, isFirstTime);
      }
    }
  };

  const getChecklists = (modelId, requestType, isFirstTime) => {
    setIsLoading(true);
    getCheckListsByModelIdAndSRTypeId(modelId, requestType, 'Individual')
      .then((result) => {
        asyncDataState.priority = true;
        if (result.status === 200) {
          let checklistoptions = [];
          result.data.map((checklist) =>
            checklistoptions.push({
              value: checklist.id,
              displayValue: checklist.name,
            })
          );

          setDataToServiceRequestForm(
            'check_list_id',
            checklistoptions,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : checklistProperties.messages.error.loadData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistProperties.messages.error.loadData,
        });
      });
  };

  // const checkForPriorityConfiguration = (isFirstTime, elId, value) => {
  //   let customerId = serviceRequestForm['customer_id'].inputProps.value;
  //   let categoryId = serviceRequestForm['product_category_id'].inputProps.value;
  //   let brandId = serviceRequestForm['product_brand_id'].inputProps.value;
  //   let modelId = serviceRequestForm['product_model_id'].inputProps.value;
  //   let requestType = serviceRequestForm['request_type'].inputProps.value;
  //   if (elId === 'customer_id') {
  //     customerId = value;
  //   }
  //   if (elId === 'product_model_id') {
  //     modelId = value;
  //   }
  //   if (elId === 'request_type') {
  //     requestType = value;
  //   }
  //   if (isFirstTime) {
  //     if (
  //       asyncDataState.customer_id &&
  //       asyncDataState.product_category_id &&
  //       asyncDataState.product_model_id &&
  //       asyncDataState.product_brand_id &&
  //       asyncDataState.request_type
  //     ) {
  //       getPriorityConifiguration(
  //         customerId,
  //         categoryId,
  //         brandId,
  //         modelId,
  //         requestType,
  //         isFirstTime
  //       );
  //     }
  //   } else {
  //     getPriorityConifiguration(
  //       customerId,
  //       categoryId,
  //       brandId,
  //       modelId,
  //       requestType,
  //       isFirstTime
  //     );
  //   }
  // };

  const getPriorityConifiguration = (
    customerId,
    categoryId,
    brandId,
    modelId,
    requestType,
    isFirstTime
  ) => {
    setIsLoading(true);
    getBestMatchPriorityConfiguration(
      customerId,
      categoryId,
      brandId,
      modelId,
      requestType
    )
      .then((result) => {
        asyncDataState.priority = true;
        setPrioriyConfiguration(result.data);
        if (result.status === 200) {
          if (result.data !== '') {
            let srForm = { ...serviceRequestForm };
            let formData = srForm['priority'];
            let inputProps = { ...formData.inputProps };
            inputProps.value = result.data.priority;
            formData.inputProps = inputProps;
            srForm['priority'] = formData;
            setServiceRequestForm(srForm);
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : priorityConfigurationProperties.messages.error.loadData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: priorityConfigurationProperties.messages.error.loadData,
        });
      });
  };

  const getProductRelatedData = (productId, isFirstTime) => {
    if (productId) {
      getAllServiceAgreementsForServiceRequest(productId, isFirstTime);
    }
  };

  const onServiceRequestSubmit = async (values, rtData) => {
    let method = '';
    let url = '';
    let data = {};
    var userId = localStorage.getItem('userId');
    let formData = { ...serviceRequestForm };
    for (let key in formData) {
      let val = formData[key].inputProps.value;
      if (key === 'accessoryIds' || key === 'stage_comment_List') {
      } else if (val !== '') {
        data[key] = val;
      }
    }

    if (typeof data['serial_number'] === 'object') {
      data['serial_number'] = data['serial_number'].value;
    }

    // if (typeof data['tid_number'] === 'object') {
    //   data['tid_number'] = data['serial_number'].value;
    // }

    data['userPriorityValue'] = data['priority'];

    if (prioriyConfiguration !== '') {
      data['systemPriorityValue'] = prioriyConfiguration.priority;
      data['priorityId'] = prioriyConfiguration.id;
    }

    data['estimated_time'] =
      parseInt(data.estimated_time_hours) * 60 +
      parseInt(data.estimated_time_minutes);
    // if (oldComments !== '') {
    //   data['comments'] = oldComments;
    // }

    if (typeof data['accessoryIds'] === 'object') {
      data['serial_number'] = data['serial_number'].value;
    }

    // data['product_id'] = productId;

    if (serviceRequestId > 0) {
      data['id'] = serviceRequestId;
      method = 'PATCH';
      url =
        UrlProperties.serviceRequest.updateServiceRequest +
        '/' +
        serviceRequestId;
      setIsNewSr(false);
    } else {
      data['id'] = 0;
      method = 'POST';
      url = UrlProperties.serviceRequest.createServiceRequest;
    }

    if (typeof rtData.currentStage !== 'undefined') {
      method = 'PATCH';
      url = 'updateWorkflowStage/' + serviceRequestId;
      for (let key in rtData) {
        data[key] = rtData[key];
      }
    }

    if (descriptionVal == '') {
      data['description'] = descriptionValDef;
    } else {
      data['description'] = descriptionVal;
    }

    if(data.assign_user_id===undefined || data.assign_user_id===null){
      data.assign_user_id=userId;
    }

    setIsLoading(true);
    await serviceRequestCalls
      .saveServiceRequest(method, url, data)
      .then(async (result) => {
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text:
              serviceRequestProperties.messages.success.saveData +
              result.data.name,
          });

          if (isNewSr && rtData.currentStage == null) {
            setServiceRequestId(result.data.id);
            await getRouteOptions(result.data.id, result.data.stage_name);
            routeHandler();
          } else {
            props.history.goBack();
          }
        } else {
          handleErrorMessage(
            result.data,
            serviceRequestProperties.messages.error.saveData
          );
        }
      })
      .catch((error) => {
        handleErrorMessage(
          error,
          serviceRequestProperties.messages.error.saveData
        );
      });
    setIsLoading(false);
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...serviceRequestForm };
    for (let key in formData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = inputProps.initvalue;
      elementData.inputProps = inputProps;
      formData[key] = elementData;
    }
    setServiceRequestForm(formData);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToServiceRequestForm = (
    key,
    options,
    isFirstTime,
    optionValue
  ) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = serviceRequestForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else if (optionValue !== undefined) {
      // default we sould not select any thing for some drop downs
      defaultValue = optionValue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    serviceRequestForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    // checkForStateUpdate(isFirstTime);
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
      }
    }
    if (status || !isFirstTime) {
      let formData = { ...serviceRequestForm };
      setServiceRequestForm(formData);
    }
  };

  const histroyDialogCloseHandler = () => {
    setIsHistoryDialogOpen(false);
  };

  const historyDialogOpenHandler = () => {
    setIsHistoryDialogOpen(true);
  };

  const estimationDialogCloseHandler = () => {
    setOpenEstimationDialog(false);
  };

  const estimationDialogOpenHandler = () => {
    setOpenEstimationDialog(true);
  };

  const preventFormSubmitFromEnter = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in serviceRequestForm) {
    formElementArray.push({
      id: key,
      config: serviceRequestForm[key],
    });
  }

  const routeHandler = () => {
    setIsWfRouteDialogOpen(true);
  };

  const routeSubmitHandler = (data) => {
    onServiceRequestSubmit('', data);
    setIsWfRouteDialogOpen(false);
  };

  const routeCloseHandler = () => {
    setIsWfRouteDialogOpen(false);
    props.history.goBack();
  };

  const fileUploadClose = () => {
    setOpenImagePreview(false);
  };

  //function for get descripions available
  const searchDescription = async (inputVal, inputProps) => {
    await serviceRequestCalls
      .searchSrDescription(inputVal)
      // axios.get("/searchSrDescription?search-text="+inputVal)
      .then((result) => {
        if (result.status == '200') {
          // commented for the payble add default values
          // let descOptions = [];
          // result.data.map(desc => descOptions.push({ "title": desc, "displayValue": desc }));
          // setDescSuggestions(descOptions);
          // descriptionVal(inputVal);

          //for payble only
          let descOptions = [
            { title: 'Charging issues', displayValue: 'Charging issues' },
            { title: 'Device issues', displayValue: 'Device issues' },
            {
              title: 'Device replacement (swipe and NFC issues)',
              displayValue: 'Device replacement (swipe and NFC issues)',
            },
            {
              title:
                'Record the activity of the agent performed at the merchant site (SLA - Colombo 24hrs, Outstation 48hrs)',
              displayValue:
                'Record the activity of the agent performed at the merchant site (SLA - Colombo 24hrs, Outstation 48hrs)',
            },
            {
              title: 'Admin handover – agent hands over rejected device to HOD',
              displayValue:
                'Admin handover – agent hands over rejected device to HOD',
            },
            {
              title:
                'Drop down list of the issue. Checklist for mandatory details to be input by agent (serial number) and image',
              displayValue:
                'Drop down list of the issue. Checklist for mandatory details to be input by agent (serial number) and image',
            },
            {
              title:
                'Agent inputs details of the serial number of the collected device and new device serial number',
              displayValue:
                'Agent inputs details of the serial number of the collected device and new device serial number',
            },
            {
              title: 'Upload image of the replaced device serial number',
              displayValue: 'Upload image of the replaced device serial number',
            },
          ];
          result.data.map((desc) => {
            if (descOptions.some((titl) => titl.title === desc)) {
            } else {
              descOptions.push({ title: desc, displayValue: desc });
            }
          });
          setDescSuggestions(descOptions);
          descriptionVal(inputVal);
          //end payble
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.searchDesc,
          });
        }
      })
      .catch((error) => {
        // notyDefault({ type: notyTypes.ERROR, text: serviceRequestProperties.messages.error.searchDesc });
      });
  };

  const onSelectDesc = (Val) => {
    setDescriptionVal(Val);
    setIsFirstClicked(false);
    setValidForm(true);
  };

  const formOnclickEvent = (Eventval) => {
    // if (descriptionVal === null || descriptionVal===""){
    //     setIsFirstClicked(true);
    //     setDescriptionVal(descriptionValDef);
    // }
  };

  const generateSRReportHandler = () => {
    setIsLoading(true);
    serviceRequestCalls
      .generateSRReport(serviceRequestId)
      .then((result) => {
        asyncDataState.product_sub_status = true;
        if (result.status === 200) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Service Request Report for SR-${serviceRequestId}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.reportGenerate,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.reportGenerate,
        });
      });
  };

  const getRequestTypeById = (id) => {
    requestTypes.map((type) => {
      if (type.value === id) {
        setRequestTypesName(type.displayValue);
      }
    });
  };

  const searchCustomer = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      setIsLoading(true);
      clearForm();
      getCustomerByName(value)
        .then((result) => {
          asyncDataState.customer_id = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerOptions = [];
              result.data.map((customer) =>
                customerOptions.push({
                  value: customer.id,
                  displayValue: customer.name + ' ' + `(${customer.address})`,
                })
              );

              let defaultVal = setDataToServiceRequestForm(
                'customer_id',
                customerOptions,
                true,
                null
              );
            }
            setIsLoading(false);
            // checkForPriorityConfiguration(true, 'customer_id', defaultVal);
            // checkForProductUpdate(defaultVal, 'customer_id', true);
          } else {
            clearForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadCustomers,
            });
          }
        })
        .catch((error) => {
          clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadCustomers,
          });
        });
      element.config.inputProps.tempValue = '';
    }
  };

  const searchCustomerByContactNo = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      setIsLoading(true);
      clearForm();
      getCustomerByContactNo(value)
        .then((result) => {
          asyncDataState.customer_id = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerContactOptions = [];
              result.data.map((customer) => {
                customerContactOptions.push({
                  value: {
                    value: customer.phone,
                    cusId: customer.id,
                  },
                  displayValue: customer.phone,
                });
              });

              setDataToServiceRequestForm(
                'phone',
                customerContactOptions,
                true,
                null
              );
            }
            setIsLoading(false);
            // checkForPriorityConfiguration(true, 'customer_id', defaultVal);
            // checkForProductUpdate(defaultVal, 'customer_id', true);
          } else {
            clearForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadCustomers,
            });
          }
        })
        .catch((error) => {
          clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadCustomers,
          });
        });
      element.config.inputProps.tempValue = '';
    }
  };

  return (
    <div>
      {openImagePreview === true ? (
        <ImagePreview
          open={openImagePreview}
          fileUploadClose={fileUploadClose}
          srID={serviceRequestId}
        />
      ) : (
        ''
      )}
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        {snNumber && (
          <WorkflowRoute
            isWfRouteDialogOpen={isWfRouteDialogOpen}
            routeOptions={routeOptions}
            routeSubmitHandler={routeSubmitHandler}
            routeCloseHandler={routeCloseHandler}
            brandID={selectedModel.brandID}
            catID={selectedModel.catID}
            modelID={selectedModel.modelID}
            serialNumber={snNumber}
            cusID={initialState.customer_id}
            serviceAgreementID={initialState.service_agreement_id}
            productID={serviceRequestForm['product_id'].inputProps.value}
            SAissuDate={saIssueDate}
            subStatusID={subStatusId}
            productSaleInvoiceNo={productDtls.saleInvoiceNumber}
            productMid={productDtls.mid}
            productSoldPrice={productDtls.soldPrice}
            loc={'serviceRequest'}
          />
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div className="generic-form-container">
                <div className={'generic-form-header-wrapper'}>
                  <div className={'generic-page-title'}>
                    <span className={'generic-page-title-icon'}>
                      <AssignmentTurnedInIcon />
                    </span>
                    <Typography
                      variant="h6"
                      style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                    >
                      {serviceRequestHeading}
                    </Typography>
                    {/* <Typography variant="body1">Manage service requests here.</Typography> */}
                  </div>
                  {serviceRequestData.serviceRequest !== null &&
                  permissions[PermissionProperties.viewHistory] ? (
                    <div style={{ float: 'right' }}>
                      <Button
                        onClick={historyDialogOpenHandler}
                        color="primary"
                        autoFocus
                      >
                        {generalProperties.history}
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <div style={{ float: 'right' }}>
                    <Button
                      onClick={generateSRReportHandler}
                      color="primary"
                      autoFocus
                    >
                      {'Print'}
                    </Button>
                  </div> */}
                </div>
                { <Dialog
                  maxWidth="lg"
                  fullWidth={true}
                  open={isHistoryDialogOpen}
                  onClose={histroyDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    {serviceRequestData.serviceRequest !== null ? (
                      <History
                        method={serviceRequestCalls.getServiceRequestHistory}
                        id={serviceRequestData.serviceRequest.id}
                      />
                    ) : (
                      ''
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={histroyDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.close}
                    </Button>
                  </DialogActions>
                </Dialog>
              /*
                <Dialog
                  maxWidth="sm"
                  fullWidth={true}
                  open={openEstimationDialog}
                  onClose={estimationDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle>
                    {`ESTIMATIONS FOR SERVICE REQUEST ${serviceRequestId}`}
                  </DialogTitle>
                  <DialogContent>
                    {estimations.length > 0 ? (
                      <GridContainer>
                        {estimations.map((estId) => (
                          <GridItem key={estId} xs={6} sm={3} md={3}>
                            <Link
                              to={{
                                pathname: `${baseUrl}/trackable/estimations/estimation?id=${estId}&isEdit=false&customerId=${serviceRequestForm['customer_id'].inputProps.value}`,
                              }}
                              target="_blank"
                            >
                              <Button size="sm" color="simple">
                                {`Estimation-${estId}`}
                              </Button>
                            </Link>
                          </GridItem>
                        ))}
                      </GridContainer>
                    ) : (
                      ''
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={estimationDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.close}
                    </Button>
                  </DialogActions>
                </Dialog> */}

                <Form
                  onSubmit={onServiceRequestSubmit}
                  initialValues={initialState}
                  validate={(values, ss) => {
                    const errors = {};
                    /*if (serviceRequestForm.name.inputProps.value === "") {
                                            // setEror("first_name", true)
                                            errors.name = GeneralProperties.emptyField;
                                        }*/
                    if (
                      serviceRequestForm.customer_id.inputProps.value === ''
                    ) {
                      errors.customer_id = GeneralProperties.emptyField;
                    }

                    if (
                      serviceRequestForm.request_type.inputProps.value === ''
                    ) {
                      errors.request_type = GeneralProperties.emptyField;
                    }

                    if (
                      serviceRequestForm.product_category_id.inputProps
                        .value === ''
                    ) {
                      errors.product_category_id = GeneralProperties.emptyField;
                    }

                    if (
                      serviceRequestForm.product_brand_id.inputProps.value ===
                      ''
                    ) {
                      errors.product_brand_id = GeneralProperties.emptyField;
                    }
                    if (
                      serviceRequestForm.product_model_id.inputProps.value ===
                      ''
                    ) {
                      errors.product_model_id = GeneralProperties.emptyField;
                    }

                    //Breakdown = 8
                    // if (serviceRequestForm.serial_number.inputProps.value === "" && serviceRequestForm.request_type.inputProps.value === 8) {
                    //     errors.serial_number = GeneralProperties.emptyField;
                    // }
                    if (
                      serviceRequestForm.serial_number.inputProps.value ===
                        '' &&
                      requestTypesName === 'Breakdown'
                    ) {
                      errors.serial_number = GeneralProperties.emptyField;
                    }

                    if (
                      serviceRequestForm.estimated_time_hours.inputProps
                        .value === '' &&
                      serviceRequestForm.estimated_time_minutes.inputProps
                        .value === ''
                    ) {
                      errors.estimated_time_hours =
                        GeneralProperties.emptyField;
                      errors.estimated_time_minutes =
                        GeneralProperties.emptyField;
                    }

                    // if (serviceRequestForm.status.inputProps.value === "") {
                    //     errors.status = GeneralProperties.emptyField;
                    // }

                    // if (serviceRequestForm.stage_id.inputProps.value === "") {
                    //     errors.stage_id = GeneralProperties.emptyField;
                    // }

                    // if (serviceRequestForm. service_agreement_id.inputProps.value === ""   && serviceRequestForm.request_type.inputProps.value === 8) {
                    //     errors. service_agreement_id = GeneralProperties.emptyField;
                    // }

                    // if (
                    //   serviceRequestForm.service_agreement_id.inputProps
                    //     .value === ''
                    // ) {
                    //   errors.service_agreement_id =
                    //     GeneralProperties.emptyField;
                    // }

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                    form,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      onKeyDown={preventFormSubmitFromEnter}
                    >
                      <CardBody>
                        <GridContainer>
                          

                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <React.Fragment key={element.id + '_frag'}>
                                  <GridItem
                                    key={element.id}
                                    xs={12}
                                    sm={12}
                                    md={mdVal}
                                  >
                                    {element.id === 'stage_comment_List' ? (
                                      <div className={styles.label}>
                                        Stage Comments
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <Field name={element.id}>
                                      {({ input, meta, options, value }) => (
                                        <div style={{ position: 'relative' }}>
                                          <CustomInput
                                            labelText={element.config.label}
                                            id={element.id}
                                            inputProps={{
                                              ...input,
                                              ...element.config.inputProps,
                                              readOnly:
                                                (isCustomerUser() &&
                                                element.id === 'newComment'
                                                  ? false
                                                  : isView) ||
                                                element.config.readOnly ||
                                                element.config.doDisable
                                                  ? true
                                                  : false,
                                            }}
                                            type={element.config.elType}
                                            formControlProps={
                                              element.config.formControlProps
                                            }
                                            adornmentText={
                                              element.config.adornmentText
                                            }
                                            adornmentPosition={
                                              element.config.adornmentPosition
                                            }
                                            changed={(event, value) => {
                                              input.onChange(event);
                                              onChangeHandeler(
                                                event,
                                                element.id,
                                                meta
                                              );
                                            }}
                                            onSelectInputChange={(event) => {
                                              element.config.inputProps.tempValue =
                                                event.target.value;
                                              if (
                                                event &&
                                                (event.key === 'Enter' ||
                                                  event.keyCode === 13)
                                              ) {
                                                if (
                                                  element.id === 'customer_id'
                                                ) {
                                                  searchCustomer(element);
                                                } else if (
                                                  element.id === 'serial_number'
                                                ) {
                                                  searchProductBySerial(
                                                    element
                                                  );
                                                } else if (
                                                  element.id === 'tid_number'
                                                ) {
                                                  searchCustomersTidHandler(
                                                    element
                                                  );
                                                } else if (
                                                  element.id === 'phone'
                                                ) {
                                                  searchCustomerByContactNo(
                                                    element
                                                  );
                                                }
                                              }
                                            }}
                                            onAdornmentClick={
                                              element.id === 'customer_id'
                                                ? () => {
                                                    searchCustomer(element);
                                                  }
                                                : element.id === 'serial_number'
                                                ? () => {
                                                    searchProductBySerial(
                                                      element
                                                    );
                                                  }
                                                : element.id === 'tid_number'
                                                ? () => {
                                                    searchCustomersTidHandler(
                                                      element
                                                    );
                                                  }
                                                : element.id === 'phone'
                                                ? () => {
                                                    searchCustomerByContactNo(
                                                      element
                                                    );
                                                  }
                                                : undefined
                                            }
                                            multiple={element.config.multiple}
                                            labelProps={{
                                              ...element.config.labelProps,
                                              error: meta.error && meta.touched,
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className={styles.formError}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </GridItem>
                                  {element.id === 'estimated_time_minutes' ? (
                                    <GridItem
                                      key={element.id + '_minute'}
                                      xs={12}
                                      sm={12}
                                      md={8}
                                    ></GridItem>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              );
                            }
                          })}
                          <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className={paperClass.root} style={{ display: jobComments && jobComments.length > 0 ? "" : "none" }}>
                                  <div className={styles.label}>
                                    Job Comments
                                  </div>
                                  <Paper elevation={0}>
                                    {jobComments &&
                                      jobComments.length > 0 &&
                                      jobComments.map(
                                        (comment, index) => {
                                          return (
                                            <div
                                              key={
                                                index +
                                                "jobComment"
                                              }
                                            >
                                              <Card
                                                className={
                                                  boxClass.root
                                                }
                                                key={index}
                                              >
                                                <CardHeader
                                                  avatar={
                                                    <Avatar
                                                      aria-label="recipe"
                                                      className={
                                                        boxClass.avatar
                                                      }
                                                      src={null}
                                                    >
                                                      {
                                                        comment.user_name.substring(0, 2)
                                                      }
                                                    </Avatar>
                                                  }
                                                  title={
                                                    <span style={{ fontWeight: "bold" }}>
                                                      {"By"}
                                                      <span style={{ fontWeight: "normal" }}>
                                                        : {comment.user_name}
                                                      </span>
                                                    </span>
                                                  }
                                                  subheader={
                                                    comment.commented_datetime
                                                  }
                                                />
                                                <CardContent>
                                                  <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                  >
                                                    <span style={{ fontWeight: "bold" }}>
                                                      {"Comment : "}
                                                    </span>
                                                    {comment.comment}
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Paper>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <Autocomplete
                                  id="Description"
                                  value={
                                    isfirstClicked
                                      ? { title: descriptionValDef }
                                      : ''
                                  }
                                  options={descSuggestions}
                                  freeSolo
                                  elType={'textarea'}
                                  getOptionLabel={(option) => option.title}
                                  renderInput={(params) => (
                                    <div style={{ padding: '20px 0' }}>
                                      <TextField
                                        variant="outlined"
                                        size={12}
                                        onChange={(event) =>
                                          searchDescription(event.target.value)
                                        }
                                        onSelect={(event) =>
                                          onSelectDesc(event.target.value)
                                        }
                                        //    onClick={(event)=> searchDescription(event.target.value)}

                                        {...params}
                                        label="Description"
                                        placeholder="Description"
                                      />
                                    </div>
                                  )}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter className={styles.buttonLeftAlign}>
                        {!isView &&
                        (permissions[PermissionProperties.editServiceRequest] ||
                          permissions[
                            PermissionProperties.addServiceRequest
                          ]) ? (
                          <div style={{ display: 'flex' }}>
                            <Button
                              className={styles.buttonMargin}
                              onClick={() => {
                                setViewMore((prevState) => !prevState);
                              }}
                              autoFocus={false}
                            >
                              {viewMore
                                ? 'Hide more details'
                                : 'Add more details'}
                            </Button>
                            <Button
                              className={styles.buttonMargin}
                              type="submit"
                              disabled={
                                (submitting || !valid || pristine) & !validForm
                              }
                              color="primary"
                              autoFocus={false}
                            >
                              {GeneralProperties.save}
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                        {isCustomerUser() ? (
                          <Button
                            className={styles.buttonMargin}
                            type="submit"
                            disabled={submitting}
                            color="primary"
                            autoFocus
                          >
                            Save Comment
                          </Button>
                        ) : (
                          ''
                        )}
                        {isEdit &&
                        (permissions[PermissionProperties.editServiceRequest] ||
                          permissions[
                            PermissionProperties.addServiceRequest
                          ]) ? (
                          <Button
                            className={styles.buttonMargin}
                            onClick={routeHandler}
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.route}
                          </Button>
                        ) : (
                          ''
                        )}

                        {isEdit || isView ? (
                          <Button
                            className={styles.buttonMargin}
                            onClick={() => {
                              setOpenImagePreview(true);
                            }}
                            color="primary"
                            autoFocus
                          >
                            Images
                          </Button>
                        ) : (
                          ''
                        )}
                        {/* {isEdit || isView ? (
                          estimations.length === 1 ? (
                            <Link
                              to={{
                                pathname: `${baseUrl}/trackable/estimations/estimation?id=${
                                  estimations[0]
                                }&isEdit=false&customerId=${
                                  serviceRequestForm['customer_id'].inputProps
                                    .value
                                }`,
                              }}
                              target="_blank"
                            >
                              <Button
                                className={styles.buttonMargin}
                                color="primary"
                                autoFocus
                              >
                                Go to Estimation {estimations[0]}
                              </Button>
                            </Link>
                          ) : estimations.length > 1 ? (
                            <Button
                              className={styles.buttonMargin}
                              onClick={() => {
                                estimationDialogOpenHandler();
                              }}
                              color="primary"
                              autoFocus
                            >
                              Estimations
                            </Button>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )} */}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ServiceRequest;

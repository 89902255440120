import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import ListAltOutlinedIcon from "@material-ui/icons/Folder";
import ReportView1 from "components/Reports/Reports new/ReportView1";
import { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import generalProperties from "../../Util/Property/GeneralProperties";
import reportsProperties, {
  reportDisabledFields,
  reportFields,
  reportHiddenFields,
  reportTypes,
  requiredFormData,
} from "../../Util/Property/OpeReportProperty";
import urlProperties from "../../Util/Property/UrlProperties";
import axios from "../../axios/axios-default";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { notyDefault, notyTypes } from "../../components/Noty/NotyCustom";
import * as actions from "../../store/actions";
// import ReportView1 from 'components/Reports/ReportView1';
import Spinner from "components/Spinner/Spinner";
import SelectionList from "../../components/Selections/SelectionList";
import ReportsService from "../../services/Reports/ReportsService";
import { fetchJourneyReport } from "./TechnicianRouteReport/utils/report-utils";

class OperationalReports extends Component {
  constructor() {
    super();
  }
  state = {
    openFileUpload: false,
    formElementArray: reportsProperties.reportForm,
    initialDataLoading: true,
    changeField: "",
    SelectedReportName: "Efficiency_Summary_Time",
    SelectedReportType: "",
    tableData: [],
    startDate: null,
    endDate: null,
    generatingReport: false,
    pdfExport: false,
    currentTechnicianName: "",
    journeyDetails: {
      totalDuration: null,
      totalDistance: null,
      totalJobDuration: null,
      totalJourneyDuration: null
    },
  };
  fileUploadClose = () => {
    this.setState({
      openFileUpload: false,
      currentTechnicianName : "",
      journeyDetails: {
        totalDuration: null,
        totalDistance: null,
        totalJobDuration: null,
        totalJourneyDuration: null
      },
      startDate: null,
      endDate: null,
      tableData: null,
    });
  };

  getReportData = async () => {
    const allUsers = await ReportsService.getAllUserForClaims();
    
    // const allCustomers = await ReportsService.getAllCustomers();
    const allProdCategories = await ReportsService.getAllProductCategories();
    const allSRCategories = await ReportsService.getServiceRequestCategories();

    localStorage.setItem("allUsers", JSON.stringify(allUsers.data));

    // localStorage.setItem('allCustomers', JSON.stringify(allCustomers.data));
    localStorage.setItem(
      "allProdCategories",
      JSON.stringify(allProdCategories.data)
    );
    localStorage.setItem(
      "allSRCategories",
      JSON.stringify(allSRCategories.data)
    );
  };

  componentDidMount() {
    this.props.getDataForReports();
    this.getReportData();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      reportFilterData,
      reportFilterDataIsLoading,
      reportFilterDataError,
    } = this.props;

    const { initialDataLoading, changeField } = this.state;
    if (
      prevProps.reportFilterData !== reportFilterData &&
      !reportFilterDataIsLoading &&
      !reportFilterDataError
    ) {
      const { formElementArray } = this.state;
      let formData = { ...formElementArray };
      if (initialDataLoading) {
        for (let key in reportsProperties.reportDataMap) {
          const dataList =
            reportFilterData[reportsProperties.reportDataMap[key]];
          if (dataList) {
            formData[key].inputProps.options = this.buildOptions(dataList);
            formData[key].inputProps.value = null;
          }
        }
        if (reportFilterData.allUsers && reportFilterData.allUsers.length > 0) {
          formData.technicianId.inputProps.options = this.buildUserOptions(
            reportFilterData.allUsers
          );
        }
      } else {
        if (changeField === "productCategoryId") {
          if (reportFilterData.allProdBrands) {
            formData.productBrandId.inputProps.options = this.buildOptions(
              reportFilterData.allProdBrands
            );
            formData.productBrandId.inputProps.value = null;
          }
          if (reportFilterData.allProdModels) {
            formData.productModelId.inputProps.options = this.buildOptions(
              reportFilterData.allProdModels
            );
            formData.productModelId.inputProps.value = null;
          }
        }
        if (changeField === "productBrandId") {
          if (reportFilterData.allProdModels) {
            formData.productModelId.inputProps.options = this.buildOptions(
              reportFilterData.allProdModels
            );
            formData.productModelId.inputProps.value = null;
          }
        }
        // if (changeField === "customerId") {
        //   if (reportFilterData.allSubCustomers) {
        //     formData.subCustomerId.inputProps.options = this.buildOptions(
        //       reportFilterData.allSubCustomers
        //     );
        //     formData.subCustomerId.inputProps.value = null;
        //   }
        // }
        if (
          changeField === "productCategoryId" ||
          // changeField === "productBrandId" ||
          // changeField === "productModelId" ||
          // changeField === "srCategoryId" ||
          changeField === "customerId"
        ) {
          if (reportFilterData.allSRPriorities) {
            formData.srPriorityId.inputProps.options = this.buildOptions(
              reportFilterData.allSRPriorities
            );
            formData.srPriorityId.inputProps.value = null;
          }
        }
      }
      formElementArray.reportStages.inputProps.value = [];
      // formElementArray.reportStagesFrom.inputProps.value = [];
      // formElementArray.reportStagesTo.inputProps.value = [];

      //Set ALL
      //console.log("form datra ",formData)
      let currentSrList = formData.srCategoryId.inputProps.options;
      let arr = [];
      let newArr = [];
      const index = currentSrList.findIndex((sr) => sr.value == -1);
      if (index == -1) {
        // arr.push({ value: -1, displayValue: "All" });
      }
      newArr = arr.concat(currentSrList);
      formData.srCategoryId.inputProps.options = newArr;

      //Set ALL

      this.setState({
        formElementArray: formData,
        initialDataLoading: false,
        formIsValid: this.isValidForm,
      });

      this.clearAllInputs();
    }
    if (
      prevProps.reportFilterData !== reportFilterData &&
      !reportFilterDataIsLoading &&
      reportFilterDataError
    ) {
      notyDefault({
        type: notyTypes.ERROR,
        text: "Error fetching report filter data",
      });
      this.setState({
        initialDataLoading: false,
      });
    }
  }
  buildOptions = (data) => {
    return data.map(({ name, id }) => ({ value: id, displayValue: name }));
  };
  buildSAOptions = (data) => {
    return data.map(({ serviceAgreementName, id }) => ({
      value: id,
      displayValue: serviceAgreementName,
    }));
  };
  buildUserOptions = (data) => {
    return data.map(({ first_name, last_name, id }) => ({
      value: id,
      displayValue: `${first_name} ${last_name}`,
    }));
  };
  resetValues = (elId) => {
    if (elId === "reportName") {
      const { formElementArray = {} } = this.state;
      const formData = { ...formElementArray };
      for (let key in formElementArray) {
        if (this.isDisabledField(key)) {
          formData[key].inputProps.value = null;
        }
      }
    }
  };
  resetSubFieldValues = (elId) => {
    const { formElementArray = {} } = this.state;
    const formData = { ...formElementArray };
    const subFields = reportsProperties.reportSubFieldMap[elId];
    formData[elId].inputProps.value = null;
    if (subFields) {
      subFields.forEach((key) => {
        formData[key].inputProps.value = null;
        if (formData[key].elType === "select") {
          formData[key].inputProps.options = [];
        }
      });
    }
    this.setState({
      formElementArray: formData,
    });
  };

  isValidForm = () => {
    const { formElementArray } = this.state;
    const mainCustomerId = formElementArray.customerId.inputProps.value;
    //const subCustomerId = formElementArray.subCustomerId.inputProps.value;
    const customerId = mainCustomerId;
    let isValidForm = true;
    for (let key in formElementArray) {
      if (!this.isDisabledField(key)) {
        if (key === "customerId") {
          isValidForm = Boolean(customerId);
        } else {
          isValidForm = Boolean(formElementArray[key].inputProps.value);
        }
        if (!isValidForm) {
          break;
        }
      }
    }
    const startDate = formElementArray.dataRangeFrom.inputProps.value;
    const endDate = formElementArray.dataRangeTo.inputProps.value;
    if (startDate && endDate && startDate.isAfter(endDate)) {
      isValidForm = false;
    }
    return isValidForm;
  };

  findById = (elemId, array = []) => {
    const item = array.find(({ id }) => elemId === id);
    if (item && item.name) {
      return item.name;
    }
    return undefined;
  };

  generateReport = () => {
    console.log(this.state.formElementArray);
  };

  fileUpload = () => {
    this.setState({
      openFileUpload: true,
    });
  };

  clearAllInputs = () => {
    const { formElementArray } = this.state;
    const formData = { ...formElementArray };
    formData.customerId.inputProps.value = null;
    formData.reportDataType.inputProps.value = null;
    formData.reportDateGroup.inputProps.value = null;
    formData.technicianId.inputProps.value = null;
    formData.srCategoryId.inputProps.value = null;
    formData.productCategoryId.inputProps.value = null;
    formData.date.inputProps.value = null;
    formData.dataRangeFrom.inputProps.value = null;
    formData.dataRangeTo.inputProps.value = null;
    formData.reportStages.inputProps.value = [];
    formData.reportStages.inputProps.options = [];
    // formData.reportStagesFrom.inputProps.options = [];
    // formData.reportStagesTo.inputProps.options = [];
    // formData.reportType.inputProps.value = null;
    // formData.reportStagesTo.inputProps.value = null;
    formData.reportType.inputProps.value = null;
    formData.customerLocation.inputProps.value = null;
    formData.department.inputProps.value = null;
    formData.dataSource.inputProps.value = null;
    this.setState({
      formElementArray: formData,
    });
  };

  downloadReport = () => {
    const { formElementArray } = this.state;
    const { reportFilterData } = this.props;
    // if (
    //   formElementArray.reportName.inputProps.value ===
    //   "TECHNICIAN_ANALYSIS_REPORT" &&
    //   (!Boolean(formElementArray.dataRangeFrom.inputProps.value) ||
    //     !Boolean(formElementArray.dataRangeTo.inputProps.value))
    // ) {
    //   notyDefault({
    //     type: notyTypes.ERROR,
    //     text: "Please Select valid Date Range"
    //   });
    //   return false;
    // }

    this.setState({
      reportDownloading: true,
    });
    const {
      allCustomers,
      allSubCustomers,
      allProdBrands,
      allProdCategories,
      allProdModels,
      allSRCategories,
      allSRPriorities,
      allUsers,
    } = reportFilterData;
    const mainCustomerId = formElementArray.customerId.inputProps.value;
    //const subCustomerId = formElementArray.subCustomerId.inputProps.value;
    const customerId = mainCustomerId;
    const reportName = formElementArray.reportName.inputProps.value;
    const reportType = formElementArray.reportType.inputProps.value;
    const technicianId = formElementArray.technicianId.inputProps.value;
    const date =
      formElementArray.date.inputProps.value &&
      formElementArray.date.inputProps.value.format("yyyy-MM-DD");
    if (reportName === "TECHNICIAN_ROUTE_REPORT") {
      //
      if (date === null || technicianId === null) {
        notyDefault({
          type: notyTypes.ERROR,
          text: "Please select a date and a technician",
        });
        this.setState({
          reportDownloading: false,
        });
        return false;
      }
      this.setState({
        reportDownloading: false,
      });
      window.open(
        this.props.match.url + "/technicianroute/" + technicianId + "/" + date
      );

      return false;
    }

    const reqBody = {
      reportName: reportName,
      reportType: reportType,
      customerId: customerId,
      customerLocation: formElementArray.customerLocation.inputProps.value,
      customerName: this.findById(customerId, allCustomers),
      dataRangeFrom:
        formElementArray.dataRangeFrom.inputProps.value &&
        formElementArray.dataRangeFrom.inputProps.value.format("yyyy-MM-DD"),
      dataRangeTo:
        formElementArray.dataRangeTo.inputProps.value &&
        formElementArray.dataRangeTo.inputProps.value.format("yyyy-MM-DD"),
      // productBrandId: formElementArray.productBrandId.inputProps.value,
      // productBrandName: this.findById(
      //   formElementArray.productBrandId.inputProps.value,
      //   allProdBrands
      // ),
      productCategoryId: formElementArray.productCategoryId.inputProps.value,
      productCategoryName: this.findById(
        formElementArray.productCategoryId.inputProps.value,
        allProdCategories
      ),
      // productModelId: formElementArray.productModelId.inputProps.value,
      // productModelName: this.findById(
      //   formElementArray.productModelId.inputProps.value,
      //   allProdModels
      // ),
      // productStatus: formElementArray.productStatus.inputProps.value,
      // saCategoeryId: formElementArray.saCategoeryId.inputProps.value,
      // saCategoryName: this.findById(
      //   formElementArray.saCategoeryId.inputProps.value,
      //   //saCategories
      // ),
      // serial: formElementArray.serial.inputProps.value,
      srCategoryId: formElementArray.srCategoryId.inputProps.value,
      srCategoryName: this.findById(
        formElementArray.srCategoryId.inputProps.value,
        allSRCategories
      ),
      // srPriorityId: formElementArray.srPriorityId.inputProps.value,
      // srPriorityName: this.findById(
      //   formElementArray.srPriorityId.inputProps.value,
      //   allSRPriorities
      // ),
      // subCustomerId: subCustomerId,
      // subCustomerName: this.findById(subCustomerId, allSubCustomers),
      technicianId: formElementArray.technicianId.inputProps.value,
      technicianName: this.findById(
        formElementArray.technicianId.inputProps.value,
        allUsers
      ),
    };
    let config = {
      responseType: "blob",
    };

    axios
      .post(urlProperties.reports.generateReport, reqBody, config)
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportName}.pdf`);
        if (reportType === "EXCEL") {
          link.setAttribute("download", `${reportName}.xlsx`);
        }
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          reportDownloading: false,
        });
      })
      .catch(() => {
        // notyDefault({
        //   type: notyTypes.ERROR,
        //   text: "Error generating report"
        // });
        this.setState({
          reportDownloading: false,
        });
      });
  };
  getKeyByValue = (obj, value) => {
    const key = Object.keys(obj).find((key) => obj[key].req === value);
    return key;
  };

  minutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  };

  assignTableData = (data) => {
    const tableArr = [];
    var num = 1;
    const fieldKeys = reportsProperties.reqFieldsMap;

    data.forEach((record) => {
      var obj = {};
      Object.assign(obj, {
        num: {
          id: "1",
          value: num,
        },
      });
      if (record["second_table"] !== undefined) {
        Object.assign(obj, {
          subTableData: record["second_table"],
        });
      }
      var keys = Object.keys(record);
      keys.forEach((field, index) => {
        const key = this.getKeyByValue(fieldKeys, field);
        const reportName = this.state.formElementArray.reportName.inputProps
          .value;
        const tableFields = requiredFormData.find(
          (ob) => ob.value === reportName
        ).tableFields.table1;

        if (key !== undefined && tableFields) {
          const id = tableFields.find((ob) => ob.field === key)?.id;
          if (id != null) {
            obj[key] = {
              id: id,
              value:
                record[field] == null
                  ? "-"
                  : key === "completedPercentage" || key === "percentage"
                  ? `${record[field]}%`
                  : key === "avgHrs" || key === "ageHrs"
                  ? this.minutesToHoursAndMinutes(record[field])
                  : record[field],
            };
          }
        } else {
          if (
            reportName === "Efficiency_Summary_Count" ||
            reportName === "Efficiency_Summary_Count_Current_Status"
          ) {
            const fieldTitleCase = field.replace(/\w\S*/g, function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });

            if (field !== "second_table") {
              obj[fieldTitleCase] = {
                id: keys.length + index,
                req: field,
                value:
                  record[field] == null
                    ? "-"
                    : key === "completedPercentage" || key === "percentage"
                    ? `${record[field]}%`
                    : key === "avgHrs" || key === "ageHrs"
                    ? this.minutesToHoursAndMinutes(record[field])
                    : record[field],
              };
            }
          }
        }
      });

      tableArr.push(obj);
      num++;
    });
    return tableArr;
  };

  submitHandler = () => {
    // validation
    const requiredList = this.checkRequired();

    if (requiredList.length !== 0) {
      const errMsg = requiredList.join(", ");
      return notyDefault({
        type: notyTypes.ERROR,
        text: `Please select a ${errMsg}`,
      });
    }

    let stageFrom = null;
    let stageTo = null;

    const { formElementArray } = this.state;

    const reportName = formElementArray.reportName.inputProps.value;
    if (reportName === "Efficiency_Summary_Time_Between_Stages") {
      stageFrom = formElementArray.reportStagesFrom.inputProps.value;
      stageTo = formElementArray.reportStagesTo.inputProps.value;
      if (stageFrom === stageTo) {
        return notyDefault({
          type: notyTypes.ERROR,
          text: `Please select distinct stages for 'Stage To' and 'Stage From'`,
        });
      }
    }

    // assign input data
    this.setState({ initialDataLoading: true, generatingReport: true });

    const mainCustomerId = formElementArray.customerId.inputProps.value;
    const customerId = mainCustomerId;

    const reportType =
      formElementArray.reportDataType.inputProps.value != null
        ? formElementArray.reportDataType.inputProps.options.find(
            (el) =>
              el.value === formElementArray.reportDataType.inputProps.value
          ).displayValue
        : null;
    const technicianId = formElementArray.technicianId.inputProps.value;
    const srCategoryId = formElementArray.srCategoryId.inputProps.value;
    const productCategoryId =
      formElementArray.productCategoryId.inputProps.value;
    const date =
      formElementArray.date.inputProps.value &&
      formElementArray.date.inputProps.value.format("yyyy-MM-DD");
    const startDate =
      formElementArray.dataRangeFrom.inputProps.value &&
      formElementArray.dataRangeFrom.inputProps.value.format("yyyy-MM-DD");
    const endDate =
      formElementArray.dataRangeTo.inputProps.value &&
      formElementArray.dataRangeTo.inputProps.value.format("yyyy-MM-DD");
    const dataSource = formElementArray.dataSource.inputProps.value;
    const customerLocation = formElementArray.customerLocation.inputProps.value;
    const stageIds = [];

    if (reportName !== "Efficiency_Summary_Time_Between_Stages") {
      if (formElementArray.reportStages.inputProps.value != null) {
        formElementArray.reportStages.inputProps.value.forEach((stage) => {
          const id = formElementArray.reportStages.inputProps.options.find(
            (el) => el.name === stage
          ).id;
          stageIds.push(id);
        });
      }
    }

    if (reportName === "TECHNICIAN_ROUTE_REPORT") {
      window.open(
        this.props.match.url + "/technicianroute/" + technicianId + "/" + date
      );
      this.setState({ initialDataLoading: false, generatingReport: false });
    } else {
      // reqBody
      const reqBody = {
        reportName: reportName,
        customerId: customerId,
        productCategoryId: productCategoryId,
        srCategoryId: srCategoryId,
        technicianId: technicianId,
        firstReportType: reportType,
        startDate: startDate,
        endDate: endDate,
        dataSource: dataSource,
        province: customerLocation,
        userId: technicianId,
      };
      if (reportName === "Efficiency_Summary_Time_Between_Stages") {
        reqBody.stageFrom = stageFrom;
        reqBody.stageTo = stageTo;
      } else {
        reqBody.stageIds = stageIds;
      }
      if (reportName === "FIELD_AGENT_JOURNEY_REPORT") {
        fetchJourneyReport(technicianId, date)
          .then((res) => {
            let { tableData, totalDistance, totalDuration, totalJobDuration, totalJourneyDuration } = res;
            tableData = this.assignTableData(tableData);

            const currentTechnicianName = formElementArray.technicianId.inputProps.options.find(
              (option) => option.value === technicianId
            )?.displayValue;

            this.setState({
              startDate: date,
              tableData: tableData,
              pdfExport: true,
              currentTechnicianName,
              journeyDetails: {
                totalDuration,
                totalDistance,
                totalJobDuration,
                totalJourneyDuration
              },
              initialDataLoading: false,
              generatingReport: false,
              openFileUpload: true,
              SelectedReportType: reportType,
            });
          })
          .catch((err) => {
            console.log(err);
            notyDefault({
              type: notyTypes.ERROR,
              text: `Unable to find journeys!`,
            });
            this.setState({
              initialDataLoading: false,
              generatingReport: false,
            });
          });
      } else {
        axios
          .post(urlProperties.reports.generateReport, reqBody)
          .then((response) => {
            const tableData = this.assignTableData(response.data);
            this.setState({
              startDate: startDate,
              endDate: endDate,
              tableData: tableData,
              initialDataLoading: false,
              generatingReport: false,
              openFileUpload: true,
              SelectedReportType: reportType,
            });
          })
          .catch((err) => {
            console.log(err);
            notyDefault({
              type: notyTypes.ERROR,
              text: err.response.data.details,
            });
            this.setState({
              initialDataLoading: false,
              generatingReport: false,
            });
          });
      }
    }
  };

  getActiveWorkFlow = async (srID) => {
    let stagesArray = [];
    let stagesArrayList = [];
    const { formElementArray } = this.state;
    this.setState({ initialDataLoading: true });
    await axios
      .get(
        "getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId=" +
          srID
      )
      .then((result) => {
        let stagesList = result.data.stages_list;
        stagesList.map((stg) => {
          stagesArray.push({ id: stg["stage_id"], name: stg["stage_name"] });
          stagesArrayList.push({
            value: stg["stage_id"],
            displayValue: stg["stage_name"],
          });
        });

        formElementArray.reportStages.inputProps.options = stagesArray;
        formElementArray.reportStagesFrom.inputProps.options = stagesArrayList;
        formElementArray.reportStagesTo.inputProps.options = stagesArrayList;

        const formData = { ...formElementArray };

        this.setState({
          formElementArray: formData,
          initialDataLoading: false,
        });
      })
      .catch((error) => {
        formElementArray.reportStages.inputProps.options = [];
        formElementArray.reportStagesFrom.inputProps.options = [];
        formElementArray.reportStagesTo.inputProps.options = [];

        this.setState({
          formElementArray: formElementArray,
          initialDataLoading: false,
        });
      });
  };

  onChangeHandler = async (event, elId) => {
    let tmp;
    let formData = { ...this.state.formElementArray };
    // console.log("tyt ",formData)
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    let value = "";
    //console.log("Event ",event);

    if (elId === "srCategoryId") {
      this.getActiveWorkFlow(event.target.value);
    }

    if (elId === "dataRangeFrom" || elId === "dataRangeTo" || elId === "date") {
      inputProps.value = event;
      value = event;
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }

    if (elId == "reportName") {
      const displayValue = formData.reportName.inputProps.options.find(
        (el) => el.value === event.target.value
      ).displayValue;
      this.setState({ SelectedReportName: displayValue });
      tmp = displayValue;
      this.clearAllInputs();
    }

    if (!value) {
      console.log(value);
      this.resetSubFieldValues(elId);
      return;
    }
    let params = {};
    for (let key in formData) {
      params[key] = formData[key].inputProps.value;
    }

    if (elId === "reportName") {
      if (value === "Efficiency_Summary_Time") {
        formData.reportType.inputProps.options = [
          {
            value: "All",
            displayValue: "All",
          },
        ];
        formData.reportType.inputProps.value = "EXCEL";
      } else if (value === "TECHNICIAN_ROUTE_REPORT") {
        formData.reportType.inputProps.options = [
          {
            value: "HTML",
            displayValue: "HTML",
          },
        ];
        formData.reportType.inputProps.value = "HTML";
      } else {
        formData.reportType.inputProps.options = reportTypes;
        formData.reportType.inputProps.value = reportTypes[0].value;
      }

      // if (value == "FIELD_AGENT_JOURNEY_REPORT") {
      //   console.log("FIELD_AGENT_JOURNEY_REPORT",formData);
      //   let options =await this.getVehicleUsers();
      //   formData.technicianId.inputProps.options = options;
      // }

      if (value == "Stock_Summery_Report") {
        formData.reportDataType.inputProps.options = [
          {
            value: "SR_WISE",
            displayValue: "SR Wise",
          },
          {
            value: "EMPLOYEE_WISE",
            displayValue: "Employee Wise",
          },
          {
            value: "PART_WISE",
            displayValue: "Part Wise",
          },
        ];
      } else {
        formData.reportDataType.inputProps.options = [
          {
            value: "SR_TYPE_WISE",
            displayValue: "SR Type Wise",
          },
          {
            value: "PRODUCT_WISE",
            displayValue: "Product Wise",
          },
          {
            value: "EMPLOYEE_WISE",
            displayValue: "Employee Wise",
          },
          {
            value: "DATE_WISE",
            displayValue: "Date Wise",
          },
        ];
      }
    }
    //   this.setState(
    //     {
    //       initialDataLoading: true
    //     },
    //     () => {
    //       this.props.getDataForReports();
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //       changeField: elId
    //     },
    //     () => {
    //       this.props.getDataForReports(elId, value, params);
    //     }
    //   );
    // }
    let validityObj = this.checkValidity(
      inputProps.value,
      elementData.validations,
      elId
    );
    elementData.valid = validityObj.isValid;
    if (typeof elementData.labelProps !== "undefined") {
      elementData.labelProps.error = !elementData.valid;
      elementData.labelProps.errormessage = validityObj.errormessage;
    }
    elementData.touched = true;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    let isValidForm = true;
    if (elId !== "reportName" && elId !== "reportType") {
      isValidForm = this.isValidForm();
    }

    this.setState(
      {
        formElementArray: formData,
        formIsValid: isValidForm,
      },
      () => this.resetValues(elId)
    );
  };

  getVehicleUsers = async () => {
    this.setState({
      isLoading: true,
    });
      await axios.get("/getVehicleUsers")
        .then((result) => {
          if (result.status === 200) {
            if (result?.data?.length > 0) {
              let userVhicleOptions = [];
              result.data.map((cust) =>
                userVhicleOptions.push({
                  value: cust.id.toString(),
                  displayValue: cust.first_name + " " + cust.last_name,
                })
              );
              return userVhicleOptions;
            }else{
              return null;
            }
            this.setState({
              isLoading: true,
            });
          } else {
            this.setState({
              isLoading: true,
            });
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : "Cannot load vehicle users",
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          notyDefault({
            type: notyTypes.ERROR,
            text: "Cannot load vehicle users",
          });
        });
        this.setState({
          isLoading: false,
        });
  };

  checkRequired() {
    const list = [];
    const { formElementArray } = this.state;
    const reportName = formElementArray.reportName.inputProps.value;

    const reqFields = requiredFormData.find((ob) => ob.value === reportName)
      .required;

    if (reqFields.length !== 0) {
      reqFields.forEach((field) => {
        const val = formElementArray[field].inputProps.value;

        if (!val || val.length === 0) {
          const displayValue = reportFields.find((ob) => ob.value === field)
            .displayValue;
          list.push(`'${displayValue}'`);
        }
      });
    }
    return list;
  }

  checkValidity(value, rules, elId) {
    let returnObj = {
      isValid: true,
      errormessage: "",
    };
    if (elId === "reportName" || elId === "reportType") {
      return returnObj;
    }
    const { formElementArray } = this.state;
    if (typeof rules == "undefined") {
      return returnObj;
    }

    if (rules.required) {
      if (typeof value !== "object") {
        if (value.trim() == "") {
          returnObj.isValid = false;
          returnObj.errormessage = generalProperties.emptyField;
        }
      } else {
        if (!value._isAMomentObject) {
          returnObj.isValid = false;
          returnObj.errormessage = generalProperties.emptyField;
        } else {
          let startDate = "";
          let endDate = "";
          if (elId === "dataRangeFrom") {
            startDate = value;
            endDate = formElementArray.dataRangeTo.inputProps.value;
          }
          if (elId === "dataRangeTo") {
            startDate = formElementArray.dataRangeFrom.inputProps.value;
            endDate = value;
          }
          if (startDate && startDate.isAfter(endDate)) {
            returnObj.isValid = false;
            returnObj.errormessage =
              reportsProperties.messages.error.invalidDateRange;
          }
        }
      }
    }

    if (rules.pattern) {
      if (!rules.pattern.test(value)) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.validEmail;
      }
    }

    return returnObj;
  }
  onFormSubmitHandler = (event) => {
    this.setState({
      // set loadin icon
      isLoading: true,
    });
    event.preventDefault();
    let formData = {};

    for (let key in this.state.formElementArray) {
      let inputPropValue = this.state.formElementArray[key].inputProps.value;
      formData[key] = inputPropValue; // get the value of each input element
    }

    // here we are not storing added or modified customers in states so we do not need reducers
    //this.props.addCustomerHandler(formData);
  };

  handleSelectedStages = (stages) => {
    if (stages.length != 0) {
      let formData = { ...this.state.formElementArray };

      if (stages.length !== formData["reportStages"].inputProps.value.length) {
        formData["reportStages"].inputProps.value = [...stages];
        this.setState({
          formElementArray: formData,
        });
      }
    }
  };

  isDisabledField(fieldId) {
    const { formElementArray = {} } = this.state;
    const reportName = formElementArray.reportName.inputProps.value;
    return reportDisabledFields[reportName].includes(fieldId);
  }

  isHiddenField(fieldId) {
    const { formElementArray = {} } = this.state;
    const reportName = formElementArray.reportName.inputProps.value;
    return reportHiddenFields[reportName].includes(fieldId);
  }

  searchCustomer(element) {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      this.setState({ initialDataLoading: true });
      ReportsService.getCustomersByName(value)
        .then((result) => {
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerOptions = [];
              result.data.map((customer) =>
                customerOptions.push({
                  value: customer.id,
                  displayValue: customer.name + " " + `(${customer.address})`,
                })
              );
              const { formElementArray } = this.state;
              let formData = { ...formElementArray };
              formData.customerId.inputProps.options = customerOptions;
              this.setState({
                formElementArray: formData,
                initialDataLoading: false,
                formIsValid: this.isValidForm,
              });
            }
            this.setState({ initialDataLoading: false });
            // checkForPriorityConfiguration(true, 'customer_id', defaultVal);
            // checkForProductUpdate(defaultVal, 'customer_id', true);
          } else {
            this.setState({ initialDataLoading: false });
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : "serviceRequestProperties.messages.error.loadCustomers",
            });
          }
        })
        .catch((error) => {
          this.setState({ initialDataLoading: false });
          notyDefault({
            type: notyTypes.ERROR,
            text: "serviceRequestProperties.messages.error.loadCustomers",
          });
        });
      element.config.inputProps.tempValue = "";
    }
  }

  render() {
    const { formElementArray = {} } = this.state;
    const { reportFilterDataIsLoading } = this.props;
    let customerFormArray = [];
    for (let key in formElementArray) {
      customerFormArray.push({
        id: key,
        config: formElementArray[key],
      });
    }

    return (
      <div>
        <LoadingOverlay
          active={this.state.initialDataLoading || reportFilterDataIsLoading}
          // spinner
          spinner={<Spinner />}
          text={this.state.generatingReport ? "Generating..." : "Loading ..."}
        >
          {this.state.openFileUpload ? (
            <ReportView1
              tableData={this.state.tableData}
              technicianName={this.state.currentTechnicianName}
              type={{
                value: formElementArray.reportName.inputProps.value,
                displayValue: this.state.SelectedReportName,
              }}
              url={urlProperties.customerUpload}
              open={this.state.openFileUpload}
              fileUploadClose={this.fileUploadClose}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              selectedReportType={this.state.SelectedReportType}
              pdfExport={this.state.pdfExport}
              journeyDetails={this.state.journeyDetails}
              // uploadLoacation={"customer"}
              // acceptFileType={".xlsx"}
            />
          ) : (
            <div></div>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <div className="generic-form-container">
                  <form onSubmit={this.onFormSubmitHandler}>
                    <div className={"generic-form-header-wrapper"}>
                      <div className={"generic-page-title"}>
                        <span className={"generic-page-title-icon"}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          className={"generic-header-text"}
                          variant="h6"
                          style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                        >
                          Reports
                        </Typography>
                        {/* <Typography variant="body1">
                          Generate your reports here.
                        </Typography> */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "73vh",
                      }}
                    >
                      <CardBody
                        style={{
                          paddingBottom: "0",
                        }}
                      >
                        <GridContainer>
                          {customerFormArray.map((element) => {
                            if (this.isHiddenField(element.id)) {
                              return;
                            }
                            if (!element.config.isHide) {
                              let mdVal = 12;
                              if (!element.config.isFullWidth) {
                                mdVal = 6;
                              }

                              if (!element.config.multiple) {
                                return (
                                  <GridItem
                                    key={element.id}
                                    xs={12}
                                    sm={12}
                                    md={mdVal}
                                  >
                                    <CustomInput
                                      labelText={element.config.label}
                                      id={element.id}
                                      formControlProps={
                                        element.config.formControlProps
                                      }
                                      inputProps={{
                                        ...element.config.inputProps,
                                        readOnly: this.isDisabledField(
                                          element.id
                                        ),
                                        disabled: this.isDisabledField(
                                          element.id
                                        ),
                                      }}
                                      disabled={this.isDisabledField(
                                        element.id
                                      )}
                                      type={element.config.elType}
                                      value={element.config.value}
                                      changed={(event) =>
                                        this.onChangeHandler(event, element.id)
                                      }
                                      onSelectInputChange={(event) => {
                                        const { formElementArray } = this.state;
                                        let formData = { ...formElementArray };
                                        formData.customerId.inputProps.tempValue =
                                          event.target.value;
                                        this.setState({
                                          formElementArray: formData,
                                        });
                                        // if (
                                        //   event &&
                                        //   (event.key === 'Enter' ||
                                        //     event.keyCode === 13)
                                        // ) {
                                        //   if (element.id === 'customerId') {
                                        //     this.searchCustomer(element);
                                        //   }
                                        // }
                                      }}
                                      onAdornmentClick={
                                        element.id === "customerId"
                                          ? () => {
                                              this.searchCustomer(element);
                                            }
                                          : undefined
                                      }
                                      error={
                                        !element.config.valid &&
                                        element.config.touched
                                      }
                                      labelProps={element.config.labelProps}
                                    />
                                  </GridItem>
                                );
                              } else {
                                return (
                                  <GridItem
                                    style={{
                                      marginLeft: "0.0rem",
                                      marginTop: "0rem",
                                    }}
                                    xs={12}
                                    sm={15}
                                    md={6}
                                  >
                                    <SelectionList
                                      lable={element.config.label}
                                      disabled={
                                        this.state.formElementArray.reportStages
                                          .inputProps.options.length > 0
                                          ? false
                                          : true
                                      }
                                      catchChange={
                                        this.state.formElementArray.reportStages
                                          .inputProps.value
                                      }
                                      data={element.config.inputProps.options}
                                      // selectedDef={data}
                                      setSelectedValue={
                                        this.handleSelectedStages
                                      }
                                      selectedValue={this.handleSelectedStages}
                                    />
                                  </GridItem>
                                );
                              }
                            }
                          })}
                        </GridContainer>
                      </CardBody>

                      {/*COMMENT: This is the final Submit Button */}
                      <CardFooter
                        style={{
                          paddingTop: "0",
                        }}
                      >
                        <Button
                          class="generic-button-class div-button-wrapper"
                          type="submit"
                          color="primary"
                          //onClick={this.searchReport}
                          // onClick={() => {
                          //   this.fileUpload();
                          //   this.downloadReport();
                          // }}
                          onClick={this.submitHandler}
                          style={{
                            width: "10vw",
                            marginTop: "0",
                          }}
                          disabled={this.state.reportDownloading}
                        >
                          {this.chkCallapi ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={21}
                            />
                          ) : (
                            "Generate Report"
                          )}
                        </Button>
                      </CardFooter>
                    </div>
                  </form>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reportFilterData: state.reportFilter.reportFilterData.data,
    reportFilterDataIsLoading: state.reportFilter.reportFilterData.isFetching,
    reportFilterDataError: state.reportFilter.reportFilterData.error,
  };
};

const mapDispatchToProps = (dispath) => {
  return {
    getDataForReports: (changeField, value, data) =>
      dispath(actions.getDataForReports(changeField, value, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationalReports);
